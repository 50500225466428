@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&display=swap");

:root {
  font-family: "Inter" !important;
  --font-family: "Inter";

  /* ----------------- Colors ---------------- */

  --color-white: #ffffff;
  --color-black: #000000;
  --color-black-1: #080808;
  --color-gray-1: #aaacae;
  --color-gray-2: #d9d9d9;
  --color-gray-3: #f6fbff;
  --color-gray-4: #4b5563;
  --color-gray-5: #64748b;
  --color-gray-6: #e3e3e3;
  --color-gray-7: #f5f5f5;
  --color-gray-8: #d1d5db;
  --color-gray-9: #67696a;
  --color-gray-10: #f0f0f0;
  --color-gray-11: #f7f7f7;
  --color-gray-12: #f0fff0;
  --color-gray-13: #eff6ff;
  --color-gray-14: #8d95a5;
  --color-gray-15: #f4f5f7;
  --color-gray-16: #e6e7e9;
  --color-gray-17: #e9ebf6;
  --color-gray-18: #bbbfc9;
  --color-gray-19: #d3d3d3;
  --color-gray-20: #e5e4e4;
  --color-gray-21: #828587;
  --color-gray-22: #a8a6a6;
  --color-gray-23: #778094;
  --color-gray-24: #a6adba;
  --color-gray-25: #c0c0c0;
  --color-red-1: #d32f2f;
  --color-red-2: #dc2626;
  --color-red-3: #ec0916;
  --color-blue-1: #334155;
  --color-blue-2: #1e293b;
  --color-blue-3: #1976d2;
  --color-blue-4: #2b2b4e;
  --color-blue-5: #051094;
  --color-blue-6: #282c34;
  --color-blue-7: #699bf7;
  --color-blue-8: #61dafb;
  --color-blue-9: #2d385066;
  --color-green-1: #66bb6a;
  --color-green-2: #16a34a;

  /* ------- Font Weight ----------- */
  --font-weight-bold: "bold";
  --font-weight-100: 100;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;

  /* ------- Font Size ----------- */
  --font-size-8: 8px;
  --font-size-9: 9px;
  --font-size-10: 10px;
  --font-size-11: 11px;
  --font-size-12: 12px;
  --font-size-13-5: 13.5px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-17: 17px;
  --font-size-18: 18px;
  --font-size-19: 19px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-0-9: 0.9rem;
  --font-size-1: 1rem;
  --font-size-3: 3rem;

  /* ------- Border Radius ----------- */
  --border-radius-0: 0px;
  --border-radius-0-8: 0.8px;
  --border-radius-1-6: 1.6px;
  --border-radius-5: 5px;
  --border-radius-8: 8px;
  --border-radius-10: 10px;
  --border-radius-20: 20px;
  --border-radius-40: 40px;
}

body {
  margin: 0;
  font-family: var(--font-family), sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
